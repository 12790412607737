.alert {
  align-items: flex-start;
  border-radius: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  padding: 20px;
  position: relative;
  width: 320px;
  color: #fff;
  #close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  &__icon {
    margin-right: 16px;
  }
  &__title {
    margin-bottom: 4px;
  }
  &__desc {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
  &__actions {
    button {
      &.primary {
        align-items: center;
        border-radius: 8px;
        border: 2px solid #fbfcfe;
        box-sizing: border-box;
        color: #fff;
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin: 20px 0px 0;
        padding: 2px 0;
        min-width: 54px;
      }
      &.secondary {
        align-items: center;
        box-sizing: border-box;
        color: #fff;
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin: 20px 0px 0;
        padding: 2px 0;
        min-width: 54px;
      }
    }
  }
  &.default {
    background: var(--gris1);
  }
  &.positive {
    background: var(--cielo);
  }
  &.warning {
    background: var(--pollito);
    color: var(--gris1);
    .alert__actions {
      button {
        &.primary {
          border: 2px solid var(--gris1);
          color: var(--gris1);
        }
        &.secondary {
          color: var(--gris1);
        }
      }
    }
  }
  &.danger {
    background: var(--sandia);
  }
}
