.thin {
  font-weight: 100;
}
.light {
  font-weight: 300;
}
.normal {
  font-weight: normal;
}
.medium {
  font-weight: 500;
}
.semibold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
.black {
  font-weight: 900;
}
.hide {
  display: none !important;
}
.uppercase {
  text-transform: uppercase;
}
.underline {
  text-decoration: underline;
}
.italic {
  font-style: italic;
}
a {
  color: inherit;
}
body {
  height: 100vh;
  overflow: hidden;
}
.container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 35px;
  position: relative;
  width: 100%;
  @media screen and (orientation: portrait) {
    padding: 0 20px;
  }
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 2.4rem;
  @media screen and (orientation: portrait) {
    font-size: 2.2rem;
  }
}
h3 {
  font-size: 3rem;
}
h4 {
  font-size: 2.5rem;
}
h5 {
  font-size: 2rem;
  @media screen and (orientation: portrait) {
    font-size: 1.7rem;
  }
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "ProximaNova";
  font-weight: bold;
}
hr {
  margin: 10px 0;
}
label {
  font-size: 1.4rem;
  font-weight: bold;
}
button {
  font-size: 1.8rem;
}
p,
span {
  font-size: 1.4rem;
}
a {
  /* Link */
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  color: #0cb5c3;
}
label {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: bold;
}

input {
  font-size: 1.4rem;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #e1e4e9;
  padding: 8px;
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: normal;
  &::placeholder {
    font-size: 1.4rem;
  }
}
.btn {
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  height: 47px;
  justify-content: center;
  border-radius: 8px;
  padding: 0 62px;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: bold;
  &.fill {
    color: #fff;
    background-color: #0cb5c3;
    border: 1px solid #0cb5c3;
    &:hover {
      background-color: transparent;
      color: #0cb5c3;
    }
  }
  &.noFill {
    background: none;
    &.white {
      border: 1px solid #fff;
      color: #fff;
    }
    &.gray {
      border: 1px solid #e1e4e9;
      color: #0cb5c3;
      background-color: #fff;
      margin-top: 20px;
      &:hover {
        background-color: #0cb5c3;
        color: #e1e4e9;
      }
    }
  }
  &[disabled] {
    opacity: 0.5;
  }
}

hr {
  border-top: 1px solid #e1e4e9;
}

small.error {
  font-size: 1rem;
  font-weight: bold;
  color: #eb5d5e;
  margin: 0 0 15px;
  display: block;
}

/* From uiverse.io by @adamgiebl */
/* Hide the default checkbox */
.containerCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.containerCheckbox {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  grid-gap: 10px;
  gap: 10px;
  color: #55637e;
  p {
    font-size: 12px;
    color: #55637e;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    gap: 5px;
  }
  a {
    text-decoration: underline;
    display: inline-block;
  }
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: #ccc;
  transition: all 0.3s;
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.containerCheckbox input:checked ~ .checkmark {
  background-color: #47da99;
  animation: pop 0.5s;
  animation-direction: alternate;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerCheckbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerCheckbox .checkmark:after {
  left: 0.45em;
  top: 0.25em;
  width: 0.25em;
  height: 0.5em;
  border: solid white;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
