.header-content {
  background: #fbfcfe;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  height: 104px;
  padding: 20px 20px 20px 26px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  h1 {
    color: #140a4c;
    font-family: "ProximaNova";
    font-size: 48px;
    font-style: normal;
    font-weight: bold;
    line-height: 58px;
  }
  img.mancha {
    right: 0;
    top: 50%;
    transform: rotate(26.9deg) translateY(-50%);
    position: absolute;
    z-index: 0;
  }
  .right {
    display: flex;
    align-items: center;
    .switch {
      background: #fbfcfe;
      border-radius: 8px;
      border: 2px solid #0cb5c3;
      box-sizing: border-box;
      margin-right: 20px;
      position: relative;
      cursor: pointer;
      z-index: 2;
      overflow: hidden;
      label {
        cursor: pointer;
        position: relative;
        input {
          cursor: pointer;
          height: 100%;
          position: absolute;
          width: 100%;
          &:checked + .mark span.day {
            background: #0cb5c3;
            border: 2px solid #0cb5c3;
            border-radius: 0px 8px 8px 0px;
            color: #fbfcfe;
          }
          & + .mark span.month {
            background: #0cb5c3;
            border-radius: 0px 8px 8px 0px;
            color: #fbfcfe;
          }
          &:checked + .mark span.month {
            background: transparent;
            border-radius: 0px 8px 8px 0px;
            color: #98a2b7;
          }
        }
        span {
          color: #98a2b7;
          font-family: "ProximaNova";
          font-size: 11px;
          font-style: normal;
          font-weight: normal;
          line-height: 16px;
          padding: 4px 15px;
        }
      }
    }
    .addEvent {
      border-radius: 8px;
      padding: 12px 17px;
      font-family: "ProximaNova";
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      position: relative;
      z-index: 5;
      background: #0cb5c3;
      color: #ffffff;
      border: 1px solid #0cb5c3;
      &:hover {
        color: #0cb5c3;
        background: #ffffff;
      }
    }
  }
}
