header {
  height: 70px;
  background-color: #0cb5c3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 20px;
  position: relative;
  width: 100%;
  height: 70px;
  background-color: #0cb5c3;
  img.logo {
    width: 95px;
    height: 40px;
    object-fit: contain;
  }
  .breadcrumb {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    li {
      display: flex;
      align-items: center;
      &.MuiBreadcrumbs-separator {
        color: #f2f2f2;
        font-size: 1.2rem;
        font-family: "ProximaNova";
        font-weight: bold;
      }
      a,
      p {
        color: #f2f2f2;
        font-size: 1.2rem;
        font-family: "ProximaNova";
        font-weight: bold;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    height: 100%;
    button,
    a,
    button {
      width: 80px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: transparent;
      small {
        font-family: "ProximaNova";
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        line-height: 10px;
        text-align: center;
        margin-top: 8px;
        color: #fbfcfe;
      }
      &.logOut {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #088893;
      }
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
      @media screen and (min-width: 768px) {
        &:hover {
          background-color: #088893;
        }
      }
    }
  }
}
