$Cielo: #0cb5c3;
$Gray_1: #140a4c;
$Gray_2: #55637e;
$Gray_3: #98a2b7;
$Gray_4: #e1e4e9;
$Morita: #8773ae;
$Pollito: #f5aa16;
$Sandía: #eb5d5e;
$Secundario_1: #3fb072;
$Secundario_2: #f0862f;
$Secundario_3: #e45e9e;
$Secundario_4: #088893;
$Secundario_5: #d7f6f9;
$Secundario_6: #f0feff;
$White: #fbfcfe;

:root {
  // CIELO
  --cielo: #0cb5c3;
  --cielo_tenue: #6dd3db;
  --cielo_hover: #098892;
  --cielo_pulsado: #136f76;
  --cielo_desactivado: #9ee1e7;
  // SANDIA
  --sandia: #eb5d5e;
  --sandia_tenue: #ef7d7e;
  --sandia_hover: #b04647;
  --sandia_pulsado: #9e2f30;
  --sandia_desactivado: #f39e9e;
  // POLLITO
  --pollito: #f5aa16;
  --pollito_tenue: #f9cc73;
  --pollito_hover: #b88010;
  --pollito_pulsado: #7b550b;
  --pollito_desactivado: #fbdda2;
  // MORITA
  --morita: #8773ae;
  --morita_tenue: #9f8fbe;
  --morita_hover: #655683;
  --morita_pulsado: #443a57;
  --morita_desactivado: #cfc7df;
  // SECUNDARIO 1
  --secundario1: #3fb072;
  --secundario1_tenue: #65c08e;
  --secundario1_hover: #2f8456;
  --secundario1_pulsado: #1a633b;
  --secundario1_desactivado: #b2dfc7;
  // SECUNDARIO 2
  --secundario2: #f0862f;
  --secundario2_tenue: #f39e59;
  --secundario2_hover: #b46523;
  --secundario2_pulsado: #a35210;
  --secundario2_desactivado: #f6b682;
  // SECUNDARIO 3
  --secundario3: #e45e9e;
  --secundario3_tenue: #e97eb1;
  --secundario3_hover: #ab4777;
  --secundario3_pulsado: #972f61;
  --secundario3_desactivado: #f4bfd8;
  // SECUNDARIO 4
  --secundario4: #088893;
  --secundario4_tenue: #39a0a9;
  --secundario4_hover: #06666e;
  --secundario4_pulsado: #0b4246;
  --secundario4_desactivado: #9ccfd4;
  // FONDO
  --fondo1: #f0feff;
  --fondo2: #d7f6f9;
  // GRISES
  --gris1: #140a4c;
  --gris2: #55637e;
  --gris3: #98a2b7;
  --gris4: #e1e4e9;
}
