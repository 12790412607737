* {
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 0;
}

input,
textarea {
  background-color: transparent;
  border: 0;
}

input[type="button"],
input[type="checkbox"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="file"],
input[type="hidden"],
input[type="image"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="radio"],
input[type="range"],
input[type="reset"],
input[type="search"],
input[type="submit"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  -moz-appearance: none;
  appearance: none;
}

[hidden] {
  display: none;
}

abbr[title] {
  border-bottom: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

a {
  text-decoration: none;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var,
optgroup {
  font-style: inherit;
  font-weight: inherit;
}

a:focus,
input[type="text"] {
  outline: 0px;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

abbr,
acronym {
  border: 0 none;
  font-variant: normal;
}

b,
strong {
  font-weight: 700;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

caption,
th {
  text-align: left;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

del,
ins {
  text-decoration: none;
}

em {
  font-style: italic;
}

fieldset,
img,
button {
  border: 0 none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

hr {
  border: 0 none;
  border-top: 1px solid #000000;
  height: 1px;
  margin: 0;
  text-align: left;
  width: 100%;
}

html {
  background: #ffffff none repeat scroll 0 0;
  color: #000000;
  font-size: 62.5%;
  font-family: sans-serif;
  /* 1 1. Set default font family to sans-serif.*/
  -ms-text-size-adjust: 100%;
  /* 2 Prevent iOS text size adjust after orientation change, without disabling user zoom.*/
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

input,
button,
textarea,
select,
optgroup,
option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

input[type="button"],
input[type="submit"] {
  cursor: hand;
  cursor: pointer;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

legend {
  border: 0;
}

mark {
  background: #ff0;
  color: #000;
}

ol {
  list-style: decimal;
}

ol li {
  list-style: decimal outside;
}

pre {
  white-space: pre-wrap;
}

q {
  quotes: "\201C""\201D""\2018""\2019";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul {
  list-style: none;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  font-size: 65%;
  vertical-align: text-top;
  top: -0.5em;
}

sub {
  vertical-align: text-bottom;
  bottom: -0.25em;
}

svg:not(:root) {
  overflow: hidden;
}

textarea {
  overflow: auto;
  /* 1 */
  resize: none;
  vertical-align: top;
  /* 2 */
}

textarea {
  box-shadow: 0;
}
