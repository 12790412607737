.segmentation {
  background: #f0feff;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #d7f6f9;
  box-sizing: border-box;
  padding: 12px 20px;
}
.options {
  margin: 8px;
  & > ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > li {
      margin-right: 7px;
      & > button {
        align-items: center;
        background: #d7f6f9;
        border-radius: 25px;
        border: 1px solid #0cb5c3;
        box-sizing: border-box;
        color: #0cb5c3;
        display: flex;
        flex-direction: row;
        font-family: "ProximaNova";
        font-size: 11px;
        font-style: normal;
        font-weight: normal;
        height: 20px;
        line-height: 16px;
        padding: 2px 4px;
        .dot {
          border-radius: 50%;
          border: 2px solid #0cb5c3;
          box-sizing: border-box;
          display: block;
          flex-grow: 0;
          flex: none;
          height: 10px;
          margin: 0 10px 0 0;
          order: 0;
          width: 10px;
        }
        img {
          margin: 0 5px 0 0;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}
.more {
  width: 100%;
  & > button {
    align-items: center;
    background: transparent;
    border-bottom: 1px solid #d7f6f9;
    color: #0cb5c3;
    display: flex;
    font-family: "ProximaNova";
    font-size: 11px;
    font-style: normal;
    line-height: 16px;
    padding: 8px 0;
    width: 100%;
    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
      margin-right: 10px;
    }
  }
}
.newSegment {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 4px 6px;
  color: #0cb5c3;
  border-radius: 8px;
  input {
    text-decoration: underline;
    color: #0cb5c3;
    width: 100%;
  }
  button {
    background: transparent;
    display: flex;
    align-items: center;
  }
}
