.calendar {
  height: 100vh;
  overflow: auto;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}
.calendar__months,
.calendar__days {
  width: 90%;
  margin: 0 auto 20px;
  overflow: hidden;
  button {
    color: #55637e;
    font-family: "ProximaNova";
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    line-height: 22px;
    text-transform: capitalize;
    background: transparent;
    small.dayName {
      font-family: "ProximaNova";
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      display: block;
      line-height: 16px;
      text-align: center;
      margin-top: 3px;
      color: #140a4c;
    }
    &:hover,
    &.active {
      color: #140a4c;
      small.dayName {
        color: #0cb5c3;
      }
    }
  }
}
.rbc-calendar {
  height: 381px;
  .rbc-header {
    span {
      font-family: "ProximaNova";
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      text-transform: uppercase;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #98a2b7;
    }
  }
  .rbc-date-cell {
    text-align: left;
    padding: 5px 10px 4px 14px;
    a {
      font-family: "ProximaNova";
      font-style: normal;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 22px;
      color: #98a2b7;
    }
  }
}
.CalendarModalForm {
  input {
    &.input__title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      text-decoration-line: underline;
      color: #55637e;
      &::placeholder {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        text-decoration-line: underline;
        color: #55637e;
      }
    }
  }
  textarea {
    font-family: "ProximaNova";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    padding: 8px 10px;
    color: #98a2b7;
    min-height: 158px;
    width: 100%;
    border-bottom: 1px solid #e1e4e9;
    &::placeholder {
      font-family: "ProximaNova";
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #98a2b7;
    }
  }
}
.rbc-time-view {
  background: #fbfcfe;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  .rbc-event {
    background: transparent !important;
    padding-left: 20px;
  }
  .event {
    display: flex;
    justify-content: space-between;
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .title-container {
        display: flex;
        align-items: center;
        img {
          width: 24px;
          margin-right: 10px;
        }
      }
    }
    .left {
      width: calc(80% - 46px);
      margin-right: 46px;
    }
    .right {
      width: 20%;
      ul {
        display: flex;
        align-items: center;
        li {
          width: 16px;
          height: 16px;
          margin-right: 10px;
          img {
            width: 16px;
            height: 16px;
            object-fit: contain;
          }
        }
      }
    }
    &-title,
    &-author {
      font-family: "ProximaNova";
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #140a4c;
    }
    &-time {
      font-family: "ProximaNova";
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 16px;
      text-align: right;
      text-transform: uppercase;
      color: #55637e;
    }
    &-description {
      font-family: "ProximaNova";
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }

  .rbc-time-header-content {
    display: none;
  }

  .rbc-label {
    font-family: "ProximaNova";
    font-style: normal;
    font-weight: bold;
    padding: 0;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #140a4c;
    justify-content: center;
  }
  .rbc-time-content,
  .rbc-timeslot-group {
    padding: 10px;
    border: 0;
  }
  .rbc-today {
    background: #fbfcfe;
  }

  .rbc-current-time-indicator {
    display: none;
  }

  .rbc-day-slot .rbc-event {
    border: 0;
    border-left: 2px solid #0cb5c3;
  }
}
.datePicker {
  display: flex;
  flex-direction: column;
  width: 250px;
  &_controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .monthSelect,
    .yearSelect {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: capitalize;
      min-width: 85px;
      button {
        background: transparent;
        opacity: 1;
        &.active {
          opacity: 0;
          pointer-events: none;
        }
      }
      span {
        font-family: "ProximaNova";
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        display: block;
        margin: 6px;
        line-height: 15px;
        text-align: center;
        color: #55637e;
      }
    }
  }
  &_controls_time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-family: "ProximaNova";
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #55637e;
    }
    .timeSelect {
      display: flex;
      align-items: center;
      input {
        font-family: "ProximaNova";
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        display: block;
        margin: 6px;
        line-height: 15px;
        color: #55637e;
        width: 80px;
        &::placeholder {
          font-family: "ProximaNova";
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          display: block;
          margin: 6px;
          line-height: 15px;
          color: #55637e;
        }
      }
    }
  }
}
