.admin__main {
  &-content {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    main {
      width: calc(100% - 216px);
      .main__container {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding-bottom: 108px;
      }
    }
  }
}
