@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
@font-face {
  font-family: "ProximaNova";
  font-weight: 900;
  src: local("ProximaNova_Black"),
    url(/assets/fonts/proximanova/ProximaNova_Black.eot),
    url(/assets/fonts/proximanova/ProximaNova_Black.woff) format("woff"),
    url(/assets/fonts/proximanova/ProximaNova_Black.ttf) format("truetype"),
    url(/assets/fonts/proximanova/ProximaNova_Black.svg) format("svg");
}
@font-face {
  font-family: "ProximaNova";
  font-weight: 700;
  src: local("ProximaNova_Bold.woff"),
    url(/assets/fonts/proximanova/ProximaNova_Bold.eot),
    url(/assets/fonts/proximanova/ProximaNova_Bold.woff) format("woff"),
    url(/assets/fonts/proximanova/ProximaNova_Bold.ttf) format("truetype"),
    url(/assets/fonts/proximanova/ProximaNova_Bold.svg) format("svg");
}
@font-face {
  font-family: "ProximaNova";
  font-weight: 800;
  src: local("ProximaNova_Extrabold.woff"),
    url(/assets/fonts/proximanova/ProximaNova_Extrabold.eot),
    url(/assets/fonts/proximanova/ProximaNova_Extrabold.woff) format("woff"),
    url(/assets/fonts/proximanova/ProximaNova_Extrabold.ttf) format("truetype"),
    url(/assets/fonts/proximanova/ProximaNova_Extrabold.svg) format("svg");
}
@font-face {
  font-family: "ProximaNova";
  font-weight: 400;
  src: local("ProximaNova_Regular"),
    url(/assets/fonts/proximanova/ProximaNova_Regular.eot),
    url(/assets/fonts/proximanova/ProximaNova_Regular.woff) format("woff"),
    url(/assets/fonts/proximanova/ProximaNova_Regular.ttf) format("truetype"),
    url(/assets/fonts/proximanova/ProximaNova_Regular.svg) format("svg");
}
@font-face {
  font-family: "ProximaNova";
  font-weight: 100;
  src: local("ProximaNova_Thin.woff"),
    url(/assets/fonts/proximanova/ProximaNova_Thin.eot),
    url(/assets/fonts/proximanova/ProximaNova_Thin.woff) format("woff"),
    url(/assets/fonts/proximanova/ProximaNova_Thin.ttf) format("truetype"),
    url(/assets/fonts/proximanova/ProximaNova_Thin.svg) format("svg");
}
@font-face {
  font-family: "ProximaNovaAlt";
  font-weight: 700;
  src: local("ProximaNovaAlt_Bold.woff"),
    url(/assets/fonts/proximanova/ProximaNovaAlt_Bold.eot),
    url(/assets/fonts/proximanova/ProximaNovaAlt_Bold.woff) format("woff"),
    url(/assets/fonts/proximanova/ProximaNovaAlt_Bold.ttf) format("truetype"),
    url(/assets/fonts/proximanova/ProximaNovaAlt_Bold.svg) format("svg");
}
@font-face {
  font-family: "ProximaNovaAlt";
  font-weight: 300;
  src: local("ProximaNovaAlt_Light"),
    url(/assets/fonts/proximanova/ProximaNovaAlt_Light.eot),
    url(/assets/fonts/proximanova/ProximaNovaAlt_Light.woff) format("woff"),
    url(/assets/fonts/proximanova/ProximaNovaAlt_Light.ttf) format("truetype"),
    url(/assets/fonts/proximanova/ProximaNovaAlt_Light.svg) format("svg");
}
@font-face {
  font-family: "ProximaNovaAlt";
  font-weight: 100;
  src: local("ProximaNovaAlt_Thin.woff"),
    url(/assets/fonts/proximanova/ProximaNovaAlt_Thin.eot),
    url(/assets/fonts/proximanova/ProximaNovaAlt_Thin.woff) format("woff"),
    url(/assets/fonts/proximanova/ProximaNovaAlt_Thin.ttf) format("truetype"),
    url(/assets/fonts/proximanova/ProximaNovaAlt_Thin.svg) format("svg");
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -10px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

@import "utilities/utilities";
@import "templates/templates";
@import "pages/pages";
@import "components/components";

.MuiMobileStepper-dot {
  background-color: rgba($color: #0cb5c3, $alpha: 0.5) !important;
  margin: 0 10px 0 0 !important;
}
.MuiMobileStepper-dotActive {
  background-color: #0cb5c3 !important;
}

.MuiButton-root:hover {
  text-decoration: none;
  background-color: transparent !important;
}

.MuiButton-root {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #0cb5c3;
  text-transform: none !important;
}

.rbc-date-cell.rbc-now {
  background: #0cb5c3;
  a {
    font-family: "ProximaNova";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #140a4c;
  }
}

.rbc-time-view .rbc-day-slot .rbc-event {
  width: 100% !important;
  left: 0 !important;
}

.rbc-month-view .rbc-event {
  background-color: transparent !important;
  color: #fff !important;
}
.rbc-month-view .rbc-today {
  background: #0cb5c3;
  border: 1px solid #088893;
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.no-suggestions {
  background: #fff;
  border-top-width: 0;
  border: 1px solid #999;
  font-family: "ProximaNova";
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  position: absolute;
  top: 100%;
  width: 150px;
  z-index: 2;
  padding: 2px;
}
.suggestions_container {
  align-items: center;
  background: #d7f6f9;
  border-radius: 25px;
  border: 1px solid #0cb5c3;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 20px;
  position: relative;
  padding: 2px 4px;
  img {
    display: block;
    height: 10px;
    margin: 0 10px 0 0;
    width: 10px;
  }
  input {
    padding: 0;
    color: #0cb5c3;
    font-family: "ProximaNova";
    font-size: 11px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
  }
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  position: absolute;
  z-index: 2;
  background: #fff;
  width: 150px;
  top: 100%;
}

.suggestions li {
  font-size: 1.2rem;
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background: #0cb5c3;
  color: #d7f6f9;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
