/* Modal */
.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 999;
}

.modal-fondo {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: rgba($color: #55637e, $alpha: 0.9) !important;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  background: white;
  border-radius: 16px;
  color: rgb(51, 51, 51);
  display: inline;
  max-width: 500px;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  outline: none;
  overflow: hidden;
  position: absolute;
  width: 100%;
  padding: 42px 40px;
  &-mancha {
    position: absolute;
    bottom: -40px;
    right: -30px;
    z-index: 0;
    pointer-events: none;
  }
  &.addEvent {
    padding: 60px 70px;
    max-width: 618px;
    // max-height: 420px;
  }
  button.backArrow,
  button#closeModal {
    background: transparent;
    display: block;
    margin-bottom: 20px;
    position: relative;
    z-index: 5;
    img {
      width: 32px;
      height: 32px;
    }
    &:hover {
      opacity: 0.5;
    }
  }
  button#closeModal {
    margin: 0 0 20px auto;
    position: absolute;
    right: 70px;
  }
  h2 {
    color: #140a4c;
    font-family: "ProximaNova";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    line-height: 29px;
    margin-bottom: 30px;
    text-align: left;
  }
  .title {
    display: flex;
    align-items: center;
    button {
      background: transparent;
      margin-right: 16px;
    }
    h3 {
      color: #140a4c;
      font-family: "ProximaNova";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0em;
      line-height: 29px;
      text-align: left;
    }
  }
  button.resendCode {
    background: transparent;
    color: #0cb5c3;
    display: block;
    font-family: "ProximaNova";
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 15px;
    text-align: center;
  }
  h5 {
    color: #140a4c;
    font-family: "ProximaNova";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    line-height: 15px;
    margin-bottom: 16px;
    text-align: left;
  }
  p {
    color: #55637e;
    font-family: "ProximaNova";
    font-size: 11px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    margin-bottom: 22px;
    width: 273px;
  }
  form {
    position: relative;
    z-index: 2;
    .code-inputs {
      display: flex;
      justify-content: space-between;
      margin: 50px 0;
      input {
        border-bottom: 2px solid #0cb5c3;
        color: #0cb5c3;
        display: block;
        font-size: 3rem;
        margin: 0 5px;
        text-align: center;
        width: 57px;
      }
    }
    textarea {
      margin-bottom: 40px;
    }
  }
}
.MuiMobileStepper-root {
  background: transparent !important;
}
.MuiButton-label {
  background: #fbfcfe;
  border-radius: 8px;
  border: 2px solid #e1e4e9;
  box-sizing: border-box;
  color: #0cb5c3;
  font-family: "ProximaNova";
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  height: 40px;
  line-height: 15px;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  width: 179px !important;
  // &:hover {
  //   background: #0cb5c3;
  //   border: 2px solid #0cb5c3;
  //   color: #fbfcfe;
  // }
}
.select-files {
  display: flex;
  justify-content: space-between;
  .files {
    details {
      border-bottom: 1px solid #e1e4e9;
      summary {
        align-items: center;
        color: #55637e;
        display: flex;
        font-family: "ProximaNova";
        font-size: 11px;
        font-style: normal;
        font-weight: normal;
        justify-content: space-between;
        line-height: 16px;
        padding: 14px 0;
        .left {
          align-items: center;
          display: flex;
        }
        img {
          display: block;
          height: 16px;
          margin-right: 16px;
          object-fit: contain;
          width: 16px;
          &.arrow {
            margin-left: 40px;
            margin-right: 0;
            transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
          }
        }
      }
      & > summary::-webkit-details-marker {
        display: none;
      }
    }
    details[open] summary {
      border-bottom: 1px solid #0cb5c3;
      padding-bottom: 15px;
      color: #0cb5c3;
      img {
        &.arrow {
          margin-left: 40px;
          margin-right: 0;
          transform: rotate(180deg);
          transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
        }
      }
    }
    details[open] summary ~ * {
      animation: sweep 0.5s ease-in-out;
    }
    .list-items {
      background: #f0feff;
      border: 1px solid #d7f6f9;
      box-sizing: border-box;
      border-radius: 8px;
      ul {
        li {
          padding: 10px 0 6px 7px;
          button {
            width: 100%;
            font-family: "ProximaNova";
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            text-align: left;
            background: transparent;
            line-height: 16px;
            color: #0cb5c3;
          }
        }
      }
    }
  }
}
.finalEvent {
  h3 {
    font-family: "ProximaNova";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #140a4c;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .guests {
    align-items: center;
    color: #140a4c;
    display: flex;
    font-family: "ProximaNova";
    font-size: 11px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    margin-bottom: 23px;
    li {
      margin-right: 36px;
    }
  }
  .desc {
    color: #55637e;
    font-family: "ProximaNova";
    font-size: 11px;
    font-style: normal;
    font-weight: normal;
    width: 100%;
    max-width: 279px;
    margin-right: 20px;
    line-height: 16px;
  }
  .attached {
    color: #140a4c;
    font-family: "ProximaNova";
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    li {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
    }
  }
}
.finishBtn {
  span.MuiButton-label {
    background: #0cb5c3 !important;
    border: 2px solid #0cb5c3 !important;
    color: #fbfcfe !important;
  }
}
.finishBtn {
  border-radius: 8px;
  box-sizing: border-box;
  font-family: "ProximaNova";
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  height: 40px;
  line-height: 15px;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  width: 179px !important;
  background: #0cb5c3 !important;
  border: 2px solid #0cb5c3 !important;
  color: #fbfcfe !important;
  margin: 20px 0 0 auto;
  display: block;
}
button.editBtn {
  align-items: center;
  background: transparent;
  color: #0cb5c3;
  display: flex;
  font-family: "ProximaNova";
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 15px;
  &.mgl {
    margin-left: 24px;
  }
  &.mgt {
    margin-top: 10px;
  }
  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    object-fit: contain;
  }
}
