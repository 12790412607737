.admin__sidebar {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  max-width: 216px;
  padding: 20px 12px;
  width: 100%;
  z-index: 5;
  &-navbar {
    details[open] summary {
      padding-bottom: 15px;
      border-bottom: 1px solid #0cb5c3;
    }
    details[open] summary ~ * {
      animation: sweep 0.5s ease-in-out;
    }
    details {
      background: #d7f6f9;
      border-radius: 8px;
      summary {
        align-items: center;
        color: #55637e;
        cursor: pointer;
        display: flex;
        font-family: "ProximaNova";
        font-size: 11px;
        line-height: 16px;
        outline: none;
        padding: 12px 4px 12px 12px;
        figure {
          object {
            width: 16px;
            height: 16px;
            margin-right: 16px;
            object-fit: contain;
          }
        }
        &:hover {
          color: #140a4c;
          figure {
            &.normalImg {
              display: none;
            }
            &.hoverImg {
              display: block;
            }
          }
        }
      }
      ul {
        padding: 12px 4px 12px 12px;
        li {
          a {
            &:hover {
              color: #140a4c;
              font-weight: bold;
              figure {
                &.normalImg {
                  display: none;
                }
                &.hoverImg {
                  display: block;
                }
              }
            }
          }
        }
      }
      & > summary::-webkit-details-marker {
        display: none;
      }
    }
    ul {
      padding: 0 4px 0 12px;
      li {
        a {
          display: block;
          padding: 15px 0;
          align-items: center;
          color: #55637e;
          display: flex;
          font-family: "ProximaNova";
          font-size: 11px;
          line-height: 16px;
          figure {
            width: 16px;
            height: 16px;
            margin-right: 16px;
            display: block;
            object {
              width: 16px;
              height: 16px;
              object-fit: contain;
            }
          }
          &:hover {
            font-weight: bold;
            color: #140a4c;
            figure {
              &.normalImg {
                display: none;
              }
              &.hoverImg {
                display: block;
              }
            }
          }
        }
      }
    }
    & > a {
      display: block;
      padding: 12px 4px 12px 12px;
      align-items: center;
      color: #55637e;
      display: flex;
      font-family: "ProximaNova";
      font-size: 11px;
      line-height: 16px;
      figure {
        width: 16px;
        height: 16px;
        margin-right: 16px;
        display: block;
        object {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }
      &:hover {
        font-weight: bold;
        color: #140a4c;
        figure {
          &.normalImg {
            display: none;
          }
          &.hoverImg {
            display: block;
          }
        }
      }
    }
  }
  &-user-info {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      object-fit: cover;
      border: 2px solid #0cb5c3;
      margin-right: 20px;
    }
    h5 {
      font-family: "ProximaNova";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #140a4c;
    }
  }
  figure {
    &.normalImg {
      display: block;
    }
    &.hoverImg {
      display: none;
    }
  }
}
